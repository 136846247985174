<template>
  <v-card flat max-width="547px" class="transparent">
    <v-card-title>
      <span class="primary--text"> LOG IN </span>
    </v-card-title>
    <v-card-text>
      <v-row>

        <v-col>
          <Github />
        </v-col>

        <v-col>
          <Facebook />
        </v-col>

      </v-row>
      <div class="mt-5 d-flex">
        <v-divider class="my-auto" ></v-divider>
        <span class="mx-5"> or </span>
        <v-divider class="my-auto"></v-divider>
      </div>

      <span class="error--text text-center mt-5" v-if="getTokenExpiredText"> {{ getTokenExpiredText }} </span>
      <v-form ref="form" v-model="valid" lazy-validation @submit.prevent="loginHandle" >
      <v-card flat class="mt-3 transparent">
        <div>
          <small
              class="red--text"
              v-for="err of login_errors"
              :key="err.code"
          >
            {{ err.message }}
          </small>
          <br/>
          <label for=""> Email address</label>
          <v-text-field
            v-model="email"
            class="rounded-lg"
            outlined
            prepend-inner-icon="mdi-email"
            :rules="emailRules"
          ></v-text-field>
        </div>

        <div>
          <label for=""> password</label>
          <v-text-field
            v-model="password"
            outlined
            class="rounded-lg"
            prepend-inner-icon="mdi-lock"
            :type="show ? 'text' : 'password'"
            :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
            @click:append="show = !show"
            :rules="passwordRules" 
          ></v-text-field>
        </div>
        <div class="d-flex">
          <v-checkbox 
            v-model="checkbox"
            class="mt-n3"
            label="Remember Me?"
          >
          </v-checkbox>
          <v-spacer></v-spacer>
          <router-link to="/password/forgot-password">Forgot password?</router-link>
        </div>
        
        <div class="">
          <v-btn type="submit" color="blue darken-4 rounded-lg" class="pa-7" dark block :loading="$apollo.loading" :disabled="!valid" >LOG IN </v-btn>
          <div class="mt-2">
            <span >Don’t have an account? <router-link to="/auth/register"> Sign Up </router-link> </span>
          </div>
        </div>
      </v-card>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import colorMxn from '@/mixins/color_mixin';
import formMxn from '@/mixins/form_mixin';
import {mapGetters} from "vuex";

export default {
  name: 'Login',
  components: {
    Facebook: () => import('./facebook'),
    Github: () => import('./github'),
  },
  mixins: [colorMxn, formMxn],
  computed: {
    ...mapGetters(["login_errors", "login_success", 'getAuthLoading', 'getTokenExpiredText']),
  },
  data() {
    return {
      valid: true,
      show: false,
      checkbox: false,
      email: '',
      password: '',
      emailRules: [
        v => !!v || "Email is required",
        v => /.+@.+/.test(v) || "E-mail must be valid"
      ],
      passwordRules: [v => !!v || "password is required"],
    }
  },
  methods:{
    loginHandle(){
      if (!this.$refs.form.validate()) {
        return;
      }
      let data = {
        password:this.password,
        email:this.email
      }
      this.$store.commit("login", data);
    }
  }
}
</script>
